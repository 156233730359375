<template>
<div class="container padding-container">
    <div class="border-container">
        <el-row class="search-container" type="flex" align="middle" justify="space-between">
            <el-col :span="4">
                <span class="list-title">班级列表</span>
            </el-col>
            <!-- <el-col :span="20" class="search-area">
                <el-col :span="5" class="search-item">
                    <el-input class="circular-bead-input" placeholder="班级名称" prefix-icon="el-icon-search"
                        v-model='search.name' @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" />
                </el-col>
            </el-col> -->
        </el-row>
        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column prop="id" label="ID" width="60" align="center" v-if='auth("ADMIN")'/>
                <el-table-column label="班级名称" min-width="150" :show-overflow-tooltip="true" align="center">
                    <div slot-scope="scope" class="fz-bold hover-text-colourful" @click="routerChange(1, scope.row)">
                        {{scope.row.name}}
                    </div>
                </el-table-column>
                <el-table-column prop="school" label="所属学校" min-width="180" align="center" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        {{scope.row.school ? scope.row.school.name : '-'}}
                    </template>
                </el-table-column>
                <el-table-column prop="student_count" label="学生数" min-width="120" align="center"/>

                <el-table-column label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="100" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="routerChange(1, scope.row)">
                            <el-tooltip placement="top" content="班级详情">
                                <i class="iconfont icon-check"></i>
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleDeleteClass(scope.row)" v-if="auth('class.update', 'ADMIN')">
                            <el-tooltip placement="top" content="删除班级">
                                <i class="iconfont icon-delete"></i>
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
</div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],

            search: {
                name: '',
            },
        };
    },
    created() {
        this.getTableData();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) { // 页码改变
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() { // 获取班级列表
            Core.Api.SchoolClass.list(
                this.currentPage,
                this.pageSize,
                0,
                this.search.name,
            ).then((res) => {
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        handleDeleteClass(item) { // 删除 班级
            console.log("handleDeleteClass id", item.id)
            this.$confirm('确定要删除这个班级吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.SchoolClass.delete(item.id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1)
                })
            })
        },
        routerChange(key, q = '') {  // 路由改变
            switch (key) {
                case 1:
                    this.$router.push({
                        path: '/class/class-detail',
                        query: {
                            org_id: q.org_id,
                            school_id: q.school_id,
                            school_name: q.school_name,
                            class_id: q.id
                        }
                    });
                    break;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container{
        @include flex(row, space-between, center);
    }
    .dialog-createBySchool {
        .dialog-content {
            .content-floor {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .key {
                    display: block;
                    width: 120px;
                }
                .el-select {
                    width: 100%;
                }
            }
        }
    }
}
</style>